import React from 'react';
import { useNavigate } from 'react-router-dom';

const StartScreen = () => {
    const navigate = useNavigate();

    const styles = {
        container: {
            boxSizing: 'border-box',
            background: '#ffffff',
            borderRadius: '24px',
            height: '100vh',
            width: '100vw',
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        svgContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'auto',
            zIndex: -1,
        },
        textMe: {
            color: '#000000',
            fontFamily: 'Poppins-Light, sans-serif',
            fontSize: '32px',
            fontWeight: 300,
            marginBottom: '20px',
        },
        button: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '23px',
            width: '308px',
            padding: '16px 24px',
            margin: '10px',
            cursor: 'pointer',
        },
        registerButton: {
            background: '#ffffff',
            border: '1px solid #000000',
        },
        registerText: {
            color: '#212529',
            fontFamily: 'Poppins-Regular, sans-serif',
            fontSize: '16px',
            fontWeight: 400,
        },
        loginButton: {
            background: '#212529',
        },
        loginText: {
            color: '#ffffff',
            fontFamily: 'Poppins-Regular, sans-serif',
            fontSize: '16px',
            fontWeight: 400,
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.svgContainer}>
                <svg
                    width="486"
                    height="791"
                    viewBox="0 0 486 791"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M85.5304 30.5114L55.4505 211.768C50.7626 212.531 46.1891 213.498 41.7281 214.808C27.0055 219.131 13.4374 227.183 0.426516 243.765L3.57347 246.235C16.0626 230.317 28.9204 222.737 42.8551 218.646C46.736 217.506 50.6954 216.637 54.7588 215.936L43.2778 285.119C42.8943 284.869 42.5125 284.613 42.1323 284.351L39.8677 287.649C40.76 288.261 41.6598 288.848 42.5673 289.407C39.4968 308.932 58.7368 323.001 75.5379 312.456C84.7995 306.643 95.2973 299.645 106.653 291.392C112.304 289.232 118.171 286.558 124.27 283.374C158.947 265.267 201.576 230.428 255.39 178.438C262.869 171.213 270.421 163.704 278.075 156.096L278.075 156.095L278.076 156.095C300.719 133.585 324.243 110.198 349.352 90.667C382.913 64.5605 418.992 45.608 459.03 44.9998L458.97 41.0002C417.671 41.6277 380.75 61.1752 346.896 87.5098C321.577 107.204 297.799 130.844 275.113 153.398L275.113 153.398L275.111 153.399C267.493 160.973 259.999 168.424 252.61 175.562C198.831 227.518 156.55 262.007 122.419 279.828C122.024 280.034 121.63 280.238 121.237 280.44C166.525 245.317 221.781 191.913 266.667 116.472C282.631 89.6403 298.728 68.8592 314.447 52.8441C346.471 25.1076 380.898 4.59428 420.03 3.99978L419.97 0.000244141C404.105 0.241258 389.039 3.64204 374.641 9.36816C374.214 9.03801 372.131 9.12269 368.362 9.27598C364.998 9.41276 360.291 9.60418 354.218 9.60418H110.193C97.9656 9.60418 87.5322 18.4488 85.5304 30.5114ZM484.97 585C443.353 585.632 411.589 614.036 383.73 646.537C375.33 656.337 367.193 666.625 359.232 676.69C354.009 683.295 348.861 689.804 343.766 696.015C330.822 711.791 318.105 725.79 304.829 735.379C203.647 808.454 108.598 789.518 66.1322 760.351L63.8677 763.649C107.76 793.794 204.653 812.662 307.171 738.621C320.895 728.71 333.885 714.364 346.858 698.552C352.058 692.214 357.238 685.663 362.469 679.048C370.372 669.053 378.391 658.912 386.767 649.14C414.574 616.7 445.311 589.603 485.03 589L484.97 585Z"
                        fill="#DADDFF"
                    />
                </svg>
            </div>
            <div style={styles.textMe}>Text me</div>
            <div style={{ ...styles.button, ...styles.registerButton }} onClick={() => navigate('/register')}>
                <div style={styles.registerText}>Register</div>
            </div>
            <div style={{ ...styles.button, ...styles.loginButton }} onClick={() => navigate('/login')}>
                <div style={styles.loginText}>Login</div>
            </div>
        </div>
    );
};

export default StartScreen;