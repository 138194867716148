import React, {useEffect, useState} from 'react';
import axios from 'axios';

const Balance = props => {
    const [balance, setBalance] = useState(0);
    const [frozenBalance, setFrozenBalance] = useState(0);
    const [error, setError] = useState('');
    const [amount, setAmount] = useState('');
    const [origin, setOrigin] = useState(''); 
    const [target, setTarget] = useState('');
    const [view, setView] = useState('balance');
    const [transactions, setTransactions] = useState([]);
    
    const fetchBalance = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://asfs-web.com/api/balance', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(response.data);
            setBalance(response.data.currentBalance);
            setFrozenBalance(Number(response.data.frozenBalance) || 0);
        }
        catch (err) {
            console.error('Error fetching balance:', err);
        }
    }

    const fetchTransactionHistory = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://asfs-web.com/api/transaction-history', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Transaction response:', response.data);
            setTransactions(response.data.transactions);
        }
        catch (err) {
            console.error('Error fetching transaction history:', err);
        }
    }
    
    useEffect(() => {
        fetchBalance();
        fetchTransactionHistory();
    }, []);
    
    const handleTransaction = async (type) => {
        if (!amount || amount <= 0) {
            setError('Enter a valid amount.');
            return;
        }

        if (type === 'deposit' && !origin) {
            setError('Enter the source of deposit.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const endpoint = type === 'deposit' ? '/api/deposit' : '/api/withdraw';

            const response = await axios.post(
                `https://asfs-web.com${endpoint}`,
                {
                    amount: parseFloat(amount),
                    ...(type === 'deposit' && { origin }),
                    ...(type === 'withdraw' && { target })
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setBalance(response.data.balance);
            setView('balance');
            setAmount('');
            setOrigin('');
            setTarget('');
            setError('');
        } catch (err) {
            console.error(`Error with ${type}:`, err);
            setError(err.response?.data?.error || 'An error occurred.');
        }
    };

    return (
        <div>
            <h2>Your Wallet</h2>
            {view === 'balance' && (
                <>
                    <h3>Balance: {balance} USD</h3>
                    <h3>Frozen Balance: {frozenBalance} USD</h3>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button onClick={() => setView('deposit')}>Deposit</button>
                    <button onClick={() => setView('withdraw')}>Withdraw</button>
                    <div>
                        <h3>Transaction History</h3>
                        <table>
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Details</th>
                                <th>Description</th> 
                            </tr>
                            </thead>
                            <tbody>
                            {transactions.map((transaction, index) => (
                                <tr key={index}>
                                    <td>{transaction.type === 'deposit' ? 'Deposit' : transaction.type === 'withdraw' ? 'Withdraw' : transaction.type === 'message' ? 'Message' : 'Message Read'}</td>
                                    <td>{transaction.amount} USD</td>
                                    <td>{new Date(transaction.timeSpan).toLocaleString()}</td>
                                    <td>
                                        {transaction.type === 'deposit'
                                            ? `From: ${transaction.origin}`
                                            : transaction.type === 'withdraw'
                                                ? `To: ${transaction.target}`
                                                : transaction.type === 'message'
                                                    ? `Message to: ${transaction.counterparty.name}`
                                                    : `Message read by: ${transaction.counterparty.name}`
                                        }
                                    </td>
                                    <td>{transaction.description}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            {(view === 'deposit' || view === 'withdraw') && (
                <>
                    <h3>{view === 'deposit' ? 'Deposit Funds' : 'Withdraw Funds'}</h3>
                    {error && <p style={{color: 'red'}}>{error}</p>}
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Enter amount"
                    />
                    {view === 'deposit' && (
                        <input
                            type="text"
                            value={origin}
                            onChange={(e) => setOrigin(e.target.value)}
                            placeholder="Source of deposit"
                        />
                    )}
                    {view === 'withdraw' && (
                        <input
                            type="text"
                            value={target}
                            onChange={(e) => setTarget(e.target.value)}
                            placeholder="Withdrawal target"
                        />
                    )}
                    <button onClick={() => handleTransaction(view)}>
                        Confirm {view === 'deposit' ? 'Deposit' : 'Withdraw'}
                    </button>
                    <button onClick={() => setView('balance')}>Cancel</button>
                </>
            )}
        </div>
    );
}
export default Balance;