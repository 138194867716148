import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Profile = () => {
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newBiography, setNewBiography] = useState('');
    const [newName, setNewName] = useState('');
    const [newPrice, setNewPrice] = useState('');
    const [newTag, setNewTag] = useState('');
    const token = localStorage.getItem('token');

    const fetchUserProfile = async () => {
        try {
            const userEmail = localStorage.getItem('userEmail');
            if (!userEmail) {
                setError('User email not found');
                setLoading(false);
                return;
            }
            const response = await axios.post(
                'https://asfs-web.com/api/profile',
                { email: userEmail },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data) {
                setUserProfile(response.data);
                setNewBiography('');
                setNewPrice('');
                setNewTag('');
            } else {
                setError('No profile data received');
            }
            setLoading(false);
        } catch (err) {
            console.log('Error fetching profile:', err);
            setError(err.response?.data?.message || err.message || 'Error fetching profile');
            setLoading(false);
        }
    };

    const handleAvatarUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onloadend = async () => {
            try {
                const base64String = reader.result;
                const response = await axios.post(
                    'https://asfs-web.com/api/upload-avatar',
                    { imageUrl: base64String },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (response.data?.avatar) {
                    setUserProfile((prevProfile) => ({
                        ...prevProfile,
                        avatar: response.data.avatar,
                    }));
                }
            } catch (err) {
                setError('Error uploading avatar');
            }
        };
        reader.onerror = () => {
            setError('Error reading file');
        };
        reader.readAsDataURL(file);
    };
    const handleKeyDown = (event, handlerFunction) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handlerFunction();
        }
    };
    const handleBiographyChange = (event) => {
        setNewBiography(event.target.value);
    };

    const handleNameChange = (event) => {
        setNewName(event.target.value);
    };

    const handleTagChange = (event) => {
        setNewTag(event.target.value);
    };

    const handlePriceChange = (event) => {
        const value = event.target.value;
        if (value === '' || /^(?:\d{1,4})?\.?\d{0,2}$/.test(value)) {
            const numericValue = parseFloat(value);
            if (isNaN(numericValue) || numericValue <= 9999.99) {
                setNewPrice(value);
            }
        }
    };

    const handleSaveBiography = async () => {
        try {
            const response = await axios.post(
                'https://asfs-web.com/api/update-biography',
                { biography: newBiography },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data?.biography) {
                setUserProfile((prevProfile) => ({
                    ...prevProfile,
                    biography: response.data.biography,
                }));
                setNewBiography('');
                setError(null);
            }
        } catch (err) {
            setError('Error updating biography');
        }
    };
    const handleSaveTag = async () => {
        try {
            const response = await axios.post(
                'https://asfs-web.com/api/update-tag',
                { tag: newTag },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data?.tag) {
                setUserProfile((prevProfile) => ({
                    ...prevProfile,
                    tag: response.data.tag,
                }));
                setNewTag('');
                setError(null);
            }
        } catch (err) {
            setError('Error updating biography');
        }
    };
    const handleSaveName = async () => {
        try {
            const response = await axios.post(
                'https://asfs-web.com/api/update-username',
                { name: newName },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data?.name) {
                setUserProfile((prevProfile) => ({
                    ...prevProfile,
                    name: response.data.name,
                }));
                setNewName('');
                setError(null);
            }
        } catch (err) {
            setError('Error updating name');
        }
    };

    const handleSavePrice = async () => {
        const numericPrice = parseFloat(newPrice);
        if (isNaN(numericPrice) || numericPrice < 0 || numericPrice > 9999.99) {
            setError('Please enter a valid positive number');
            return;
        }
        try {
            const response = await axios.post(
                'https://asfs-web.com/api/update-price',
                { pricePerMessage: newPrice },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data?.pricePerMessage) {
                setUserProfile((prevProfile) => ({
                    ...prevProfile,
                    pricePerMessage: response.data.pricePerMessage,
                }));
                setNewPrice('');
                setError(null);
            }
        } catch (err) {
            setError('Error updating price');
        }
    };

    const handleRedirectToBalance = () => {
        window.location.href = 'https://asfs-web.com/wallet';
    };

    useEffect(() => {
        if (!token) {
            setError('No token found. Please log in.');
            setLoading(false);
            return;
        }
        fetchUserProfile();
    }, [token]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="profile-container">
            <h2 style={{textAlign: 'center'}}>User Profile</h2>
            {userProfile ? (
                <div>
                    <div className="avatar-section">
                        {userProfile.avatar && (
                            <img
                                src={userProfile.avatar}
                                alt="User Avatar"
                                style={{maxWidth: '300px', display: 'block', marginBottom: '10px'}}
                            />
                        )}
                    </div>
                    <p><strong>ID:</strong> {userProfile.id}</p>
                    <p><strong>Name:</strong> {userProfile.name}</p>
                    <p><strong>Tag:</strong> {userProfile.tag || 'Tag not found'}</p>
                    <p><strong>Price per message:</strong> ${userProfile.pricePerMessage}</p>
                    <p><strong>Biography:</strong> {userProfile.biography}</p>
                    <p><strong>Current Balance:</strong> ${userProfile.currentBalance}</p>
                    <p><strong>Frozen Balance:</strong> ${userProfile.frozenBalance}</p>
                    <br/>
                    <p><strong>Edit your profile:</strong></p>
                    <div className="upload-section">
                        <p>Update Avatar:</p>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleAvatarUpload}
                            style={{marginTop: '10px'}}
                        />
                    </div>
                    <div className="biography-section" style={{marginTop: '20px'}}>
                        <p>Update Biography:</p>
                        <textarea
                            value={newBiography}
                            onChange={handleBiographyChange}
                            onKeyDown={(e) => handleKeyDown(e, handleSaveBiography)}
                            placeholder="Write your biography here"
                            rows="4"
                            cols="50"
                            style={{
                                width: '12%',
                                resize: 'none',
                                marginBottom: '10px',
                            }}
                        />
                        <div>
                            <button onClick={handleSaveBiography}>Save Biography</button>
                        </div>
                    </div>
                    <div className="update-name">
                        <p>Update Name:</p>
                        <textarea
                            value={newName}
                            onChange={handleNameChange}
                            onKeyDown={(e) => handleKeyDown(e, handleSaveName)}
                            placeholder="Write your new Name"
                            rows="4"
                            cols="50"
                            style={{
                                width: '12%',
                                height: '15px',
                                resize: 'none',
                                marginBottom: '10px',
                            }}
                        />
                        <div>
                            <button onClick={handleSaveName}>Save new Name</button>
                        </div>
                    </div>
                    <div className="update-tag">
                        <p>Update Tag:</p>
                        <input
                            type="text"
                            value={newTag}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (!value.startsWith('@')) {
                                    value = '@' + value;
                                }
                                setNewTag(value);
                            }}
                            onKeyDown={(e) => handleKeyDown(e, handleSaveTag)}
                            placeholder="@username"
                            style={{
                                width: '12%',
                                padding: '5px',
                                marginBottom: '10px'
                            }}
                        />
                        <div>
                            <button onClick={handleSaveTag}>Save new Tag</button>
                        </div>
                    </div>
                    <div className="update-price">
                        <p>Update Price for message:</p>
                        <textarea
                            value={newPrice}
                            onChange={handlePriceChange}
                            onKeyDown={(e) => handleKeyDown(e, handleSavePrice)}
                            placeholder="Write new Price for message"
                            rows="4"
                            cols="50"
                            style={{
                                width: '12%',
                                height: '30px',
                                resize: 'none',
                                marginBottom: '10px',
                            }}
                        />
                        <div>
                            <button onClick={handleSavePrice}>Save new price for message</button>
                        </div>
                    </div>
                    <div className="wallet">
                        <p><strong>Check your wallet</strong></p>
                        <button onClick={handleRedirectToBalance}>Go to Balance</button>
                    </div>
                </div>
            ) : (
                <p>No profile data found.</p>
            )}
        </div>
    );
};

export default Profile;