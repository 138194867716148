import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StartScreen from './components/StartScreen';
import Register from './components/Register';
import Login from './components/Login';
import Chat from './components/Chat';
import Balance from "./components/Wallet";
import Profile from "./components/Profile";
import UserProfileView from './components/UserProfile';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<StartScreen />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/wallet" element={<Balance />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/:tag" element={<UserProfileView />} />
            </Routes>
        </Router>
    );
};

export default App;
