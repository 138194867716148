import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ChatList from './ChatList';

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState('');
    const [selectedChat, setSelectedChat] = useState(null);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [showChatList, setShowChatList] = useState(true);
    const token = localStorage.getItem('token');
    /* Рассказываю прикол про JS, тут чтобы привести в int нужно не только парсе инт прописать, а ещё и разрядность указать*/
    const currentUserId = parseInt(localStorage.getItem('userId'), 10);
    const messageContainerRef = useRef(null);
    const lastMessageIdRef = useRef(null);
    const socketRef = useRef(null);
    const isSocketInitialized = useRef(false); // Флаг инициализации WebSocket

    const fetchMessages = async (chatId, lastMessageId) => {
        if (!chatId || isLoading) return;

        setIsLoading(true);
        try {
            const response = await axios.get(`https://asfs-web.com/api/messages/${chatId}`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { limit: 15, beforeMessageId: lastMessageId },
            });

            if (response.data.length === 0) {
                setHasMoreMessages(false);
            } else {
                setMessages((prevMessages) => [...response.data, ...prevMessages]);
                lastMessageIdRef.current = response.data[0]?.id || null;
            }
        } catch (err) {
            console.error('Error fetching messages:', err);
        }
        setIsLoading(false);
    };

    const sendMessage = async () => {
        if (!selectedChat || !content.trim()) return;
        try {
            // Отправляем сообщение на сервер через API
            const response = await axios.post(
                'https://asfs-web.com/api/messages',
                { chatId: selectedChat.id, content },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // Отправляем сообщение через WebSocket, только если оно успешно добавлено на сервер
            if (socketRef.current?.readyState === WebSocket.OPEN) {
                socketRef.current.send(JSON.stringify(response.data));
            }

            setContent('');
        } catch (err) {
            console.error('Error sending message:', err);
        }
    };

    // WebSocket Handlers
    useEffect(() => {
        if (selectedChat && messages.length > 0) {
            const unreadMessageIds = messages
                .filter((message) => !message.isRead && message.senderId !== currentUserId)
                .map((message) => message.id);

            if (unreadMessageIds.length > 0) {
                // Отправляем уведомление о прочтении через WebSocket
                if (socketRef.current?.readyState === WebSocket.OPEN) {
                    socketRef.current.send(
                        JSON.stringify({
                            type: 'markAsRead',
                            chatId: selectedChat.id,
                            messageIds: unreadMessageIds,
                        })
                    );
                }

                // Помечаем сообщения как прочитанные через API
                markMessagesAsRead(unreadMessageIds);
            }
        }
    }, [selectedChat, messages]);

    const markMessagesAsRead = async (messageIds) => {
        try {
            await axios.put(
                'https://asfs-web.com/api/messages/read',
                { messageIds },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    messageIds.includes(message.id) ? { ...message, isRead: true } : message
                )
            );
        } catch (err) {
            console.error('Error marking messages as read:', err);
        }
    };

    useEffect(() => {
        if (!isSocketInitialized.current) {
            socketRef.current = new WebSocket('wss://asfs-web.com/ws/');
            isSocketInitialized.current = true;

            socketRef.current.onopen = () => {
                console.log('WebSocket connected.');
                socketRef.current.send(
                    JSON.stringify({
                        type: 'authenticate',
                        userId: currentUserId,
                    })
                );
            };

            socketRef.current.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    console.log('Received message:', data);

                    if (data.type === 'message') {
                        console.log('Received message: ', data);
                        console.log('Sender id is: ', data.message.senderId);
                        console.log('Message id is: ', data.message.id);

                        const newMessage = {
                            id: data.message.id,
                            chatId: data.chatId,
                            senderId: data.message.senderId,
                            receiverId: data.message.receiverId,
                            content: data.message.content,
                            Sender: data.message.Sender,
                            Receiver: data.message.Receiver,
                            SenderContact: data.message.SenderContact,
                            isRead: false,
                        }

                        setMessages((prevMessages) => [...prevMessages, newMessage]);
                        markMessagesAsRead([newMessage.id]);
                    }
                    if (data.type === 'markAsRead') {
                        console.log('Marking messages as read:', data.messageIds);

                        // Обновление состояния
                        setMessages((prevMessages) => {
                            const updatedMessages = prevMessages.map((message) => {
                                if (data.messageIds.includes(message.id)) {
                                    console.log(`Updating message ID: ${message.id} to isRead: true`);
                                    return { ...message, isRead: true };
                                }
                                return message;
                            });
                            console.log('Updated messages:', updatedMessages);
                            return updatedMessages;
                        });
                    }
                } catch (error) {
                    console.error('Error processing message WebSocket:', error);
                }
            };

            socketRef.current.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            const reconnect = () => {
                setTimeout(() => {
                    if (!isSocketInitialized.current) {
                        // Переинициализируем сокет
                        socketRef.current = new WebSocket('wss://asfs-web.com/ws/');
                        isSocketInitialized.current = true;
                    }
                }, 5000); // пингуем раз в 5 сек
            };

            socketRef.current.onclose = () => {
                console.log('WebSocket connection closed.');
                isSocketInitialized.current = false;
                reconnect();
            };

        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [currentUserId]);

    useEffect(() => {
        if (selectedChat) {
            setMessages([]);
            setHasMoreMessages(true);
            lastMessageIdRef.current = null;
            fetchMessages(selectedChat.id);
            setShowChatList(false);
        }
    }, [selectedChat]);

    const handleScroll = () => {
        if (messageContainerRef.current.scrollTop === 0 && hasMoreMessages && !isLoading) {
            fetchMessages(selectedChat.id, lastMessageIdRef.current);
        }
    };

    const markAllVisibleMessagesAsRead = async (messages) => {
        const unreadMessageIds = messages
            .filter((message) => message.senderId !== currentUserId && !message.isRead)
            .map((message) => message.id);

        if (unreadMessageIds.length > 0) {
            try {
                await axios.put(
                    'https://asfs-web.com/api/messages/read',
                    { messageIds: unreadMessageIds },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setMessages((prevMessages) =>
                    prevMessages.map((message) =>
                        unreadMessageIds.includes(message.id) ? { ...message, isRead: true } : message
                    )
                );
            } catch (err) {
                console.error('Error marking all messages as read:', err);
            }
        }
    };

    const createChat = async (userId) => {
        try {
            console.log("receiverId", userId);
            const response = await axios.post(
                'https://asfs-web.com/api/chats',
                { userId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setSelectedChat(response.data);
        } catch (err) {
            console.error('Error creating chat:', err);
        }
    };
    const searchUsers = async (query) => {
        if (query.length < 3) return;
        setIsSearching(true);
        try {
            const response = await axios.get('https://asfs-web.com/api/search-users', {
                headers: { Authorization: `Bearer ${token}` },
                params: { nickname: query },
            });
            setUsers(response.data);
        } catch (err) {
            console.error('Error searching users:', err);
        } finally {
            setIsSearching(false);
        }
    };
    const styles = {
        container: {
            background: '#ffffff',
            borderRadius: '24px',
            height: '100vh',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto 1fr auto',
            overflow: 'hidden',
        },
        header: {
            background: '#daddff',
            padding: '8px 16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '0 0 0 25px',
            overflow: 'hidden',
        },
        textMe: {
            color: '#000000',
            fontFamily: 'Poppins-Light, sans-serif',
            fontSize: '1.5rem',
            fontWeight: 300,
            marginBottom: '10px',
            textAlign: 'center',
        },
        inputContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '8px',
            alignItems: 'center',
        },
        input: {
            width: '100%',
            padding: '8px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            fontSize: '0.9rem',
        },
        chatList: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            padding: '8px',
            width: '100%',
        },
        messageContainer: {
            flex: 1,
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 120px)',  // Reduced height to avoid overlap
            position: 'relative',
        },
        sendButton: {
            backgroundColor: '#212529',
            borderRadius: '16px',
            color: '#ffffff',
            padding: '8px 16px',
            fontSize: '0.9rem',
            width: 'auto',
            marginTop: '10px',
            cursor: 'pointer',
            maxWidth: '60vw',
        },
        inputAutofill: {
            backgroundColor: 'transparent !important',
            color: '#212529 !important',
        },
        message: {
            padding: '10px',
            margin: '5px 0',
            borderRadius: '8px',
            background: '#f1f1f1',
        },
        messageInput: {
            background: '#ffffff',
            position: 'fixed',  // Change to fixed for staying at the bottom
            bottom: '0',
            left: '10px',
            right: '10px',
            width: 'calc(100% - 20px)',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '10px 0',
            zIndex: 1,  // Ensure it appears above other content
        },
    };

// JSX
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <div style={styles.textMe}>Text me</div>
                <div style={styles.inputContainer}>
                    <input
                        type="text"
                        style={styles.input}
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            if (e.target.value.length >= 3) {
                                searchUsers(e.target.value);
                            } else {
                                setUsers([]);
                            }
                        }}
                        placeholder="Find chat!"
                    />
                    {isSearching && <p>Loading...</p>}
                    <ul>
                        {users.map((user) => (
                            <li key={user.id} onClick={() => createChat(user.id)}>
                                {user.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div style={styles.chatList}>
                <ChatList onSelectChat={setSelectedChat} />
            </div>
            <div style={styles.messageContainer} ref={messageContainerRef} onScroll={handleScroll}>
                <h2>Chat with {selectedChat?.name || '...'}</h2>
                <div ref={messageContainerRef} onScroll={handleScroll}>
                    {messages.map((message) => {
                        const senderName = message.SenderContact?.subjectName || message.Sender?.name;

                        return (
                            <div key={message.id} style={{...styles.message, borderBottom: '1px solid #ccc'}}>
                                <p style={{margin: '0', fontWeight: 'bold'}}>
                                    <strong>{message.senderId === currentUserId ? 'You' : senderName}:</strong> {message.content}
                                </p>
                                {message.senderId === currentUserId && (
                                    <p style={{
                                        fontStyle: 'italic',
                                        color: message.isRead ? 'green' : 'gray',
                                        marginTop: '5px'
                                    }}>
                                        {message.isRead ? 'Read' : 'Unread'}
                                    </p>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div style={styles.messageInput}>
                <input
                    type="text"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="Type a message"
                    style={styles.input}
                />
                <button style={styles.sendButton} onClick={sendMessage}>Send</button>
            </div>
        </div>
    );
};


export default Chat;