import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const UserProfile = () => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { tag } = useParams();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`https://asfs-web.com/api/${tag}`);
                setProfile(response.data);
            } catch (err) {
                console.log('Error fetching profile:', err);
                setError(err.response?.data?.message || 'Ошибка загрузки профиля');
            } finally {
                setLoading(false);
            }
        };
        fetchProfile();
    }, [tag]);

    if (loading) return <div className="loading">Загрузка...</div>;
    if (error) return <div className="error">Ошибка: {error}</div>;
    if (!profile) return <div className="not-found-profile">Профиль не найден</div>;

    return (
        <div className="profile-container">
            <h2 className="profile-name">Профиль: {profile.name}</h2>
            <div className="info-profile">
                <div className="avatar-section">
                    {profile.avatar && (
                        <img
                            src={profile.avatar}
                            alt="Аватар пользователя"
                            style={{ maxWidth: '300px', display: 'block', marginBottom: '10px' }}
                        />
                    )}
                </div>
                <div className="info-section">
                    <div className="tag-section">
                        <p>Тег: {profile.tag || 'Тег отсутствует'}</p>
                    </div>
                    <div className="biography-section">
                        <p>Биография: {profile.biography || 'Нет биографии'}</p>
                    </div>
                    <div className="price-per-message">
                        <p>Цена за сообщение: ${profile.pricePerMessage}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
