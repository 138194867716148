import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://asfs-web.com/api/register', { name, email, password });
            navigate('/login');
        } catch (err) {
            setError('Registration failed. Please try again.');
        }
    };

    const styles = {
        container: {
            background: '#ffffff',
            borderRadius: '24px',
            height: '100vh',
            width: '100vw',
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputContainer: {
            padding: '16px 0px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: 'center',
            width: '100%',
        },
        inputBox: {
            background: '#f8f9fa',
            borderRadius: '16px',
            padding: '16px',
            width: '327px',
        },
        input: {
            border: 'none',
            outline: 'none',
            background: 'transparent !important',
            width: '90%',
            fontFamily: 'Poppins-Regular, sans-serif',
            fontSize: '16px',
            color: '#212529',
        },
        buttonContainer: {
            background: '#212529',
            borderRadius: '16px',
            width: '100%',
            display: 'flex',
            marginTop: '20px',
        },
        button: {
            color: '#ffffff',
            textAlign: 'center',
            fontFamily: 'Poppins-Regular, sans-serif',
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '15px',
            padding: '14px 0px',
            border: 'none',
            width: '100%',
            background: 'none',
            cursor: 'pointer',
        },
        error: {
            color: 'red',
            textAlign: 'center',
        },
    };

    return (
        <div style={styles.container}>
            {error && <p style={styles.error}>{error}</p>}
            <form onSubmit={handleRegister}>
                <div style={styles.inputContainer}>
                    <div style={styles.inputBox}>
                        <input
                            type="text"
                            style={styles.input}
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div style={styles.inputContainer}>
                    <div style={styles.inputBox}>
                        <input
                            type="email"
                            style={styles.input}
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div style={styles.inputContainer}>
                    <div style={styles.inputBox}>
                        <input
                            type="password"
                            style={styles.input}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div style={styles.buttonContainer}>
                    <button type="submit" style={styles.button}>Register</button>
                </div>
            </form>
        </div>
    );
};

export default Register;
