import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ChatList = ({ onSelectChat }) => {
    const [chats, setChats] = useState([]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const response = await axios.get('https://asfs-web.com/api/chats', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setChats(response.data);
            } catch (err) {
                console.error('Error fetching chats:', err);
            }
        };

        fetchChats();
    }, [token]);

    return (
        <div>
            <h3>Chats</h3>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {chats.map((chat) => (
                    <li
                        key={chat.id}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                            padding: '5px',
                        }}
                        onClick={() => onSelectChat(chat)}
                    >
                        <img
                            src={chat.avatar || 'https://i.imgur.com/8JO1Ni3.png'}
                            alt="Avatar"
                            style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                marginRight: '10px',
                            }}
                        />
                        <span style={{ fontWeight: 'bold' }}>{chat.name}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ChatList;
